<template>
  <v-card>
    <v-card-title></v-card-title>
    <v-data-table
      :headers="headers"
      :items="peoples"
      :options.sync="options"
      :server-items-length="peoplesTotal"
      :loading="loading"
      :disable-filtering="true"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions:[10, 20, 50],
        itemsPerPageText: $t('dataTable.itemsPerPageText'),
        pageText: $t('dataTable.pageText')
      }"
      :hide-default-header="!$vuetify.breakpoint.mobile"
      @update:page="fetch"
      @update:items-per-page="fetch"
    >
      <template v-if="!$vuetify.breakpoint.mobile" v-slot:header="{ props }">
        <datatable-header :props="props" :options.sync="options" :translation-key="translationKey" @update:filters="fetch"></datatable-header>
      </template>

      <template v-slot:item.enter_all_cars="{ item }">
        <v-chip
          :color="item.enter_all_cars ? 'success' : 'error'"
          label
          outlined
          small
        >
          {{ item.enter_all_cars ? 'مسموح' : 'غير مسموح' }}
        </v-chip>
      </template>
      <template v-slot:item.active="{ item }">
        <v-btn
          v-can.disable="'update_peoples'"
          depressed
          :color="item.active ? 'success' : 'error'"
          rounded
          small
          outlined
        >
          {{ item.active ? 'مفعل' : 'معطل' }}
          <v-icon
            dark
            right
          >
            {{ item.active ? 'mdi-check' : 'mdi-block-helper' }}
          </v-icon>
        </v-btn>
      </template>

      <template v-slot:item.identifier_name="{ item }">
        <v-chip
          v-if="item.identifier"
          label
          color="black"
          small
          dark
        >
          {{ item.identifier.name }}
        </v-chip>
      </template>
      <template v-slot:item.created_at="{item}">
        {{ item.created_at | format_date }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from '@axios'
import DatatableHeader from '@/components/dataTables/DatatableHeader.vue'
import { ObjectToQuery } from '@/plugins/helper';
import { getFilters, getOptions } from '@/components/dataTables/helper'

export default {
  name: 'People',
  components: { DatatableHeader },
  data: () => ({
    translationKey: 'pages.peoples',
    filters: false,
    peoples: [],
    citizen: 0,
    peoplesTotal: 0,
    loading: true,
    options: {},
    tempOptions: '',
    search: '',
    dialogData: {
      dialog: false,
      type: 'details',
      id: null,
    },
    headers: [
      {
        text: 'number',
        align: 'center',
        sortable: false,
        value: 'id',
        sort: true,
        filter: {
          menu: false,
          type: 'number',
        },
      },
      {
        text: 'name',
        value: 'name',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'page',
        value: 'page',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'sequence',
        value: 'sequence',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'identifier',
        value: 'identifier_name',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'enter_all_cars',
        value: 'enter_all_cars',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'switch',
          label: 'مسموح',
        },
      },
      {
        text: 'active',
        value: 'active',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'switch',
          label: 'مفعل',
        },
      },
      {
        text: 'expire_date',
        value: 'expire_date',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'date',
          methods: ['between', 'greater', 'less', 'equal', 'notEqual'],
        },
      },
      {
        text: 'created_at',
        value: 'created_at',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'date',
          methods: ['between', 'greater', 'less', 'equal', 'notEqual'],
        },
      },
    ],
  }),
  setup() {
    return {

    }
  },
  mounted() {
    this.options = getOptions(this.$route, this.options);
    this.fetch();
  },

  methods: {
    async fetch() {
      if (JSON.stringify(this.options) !== this.tempOptions) {
        this.tempOptions = JSON.stringify(this.options);
        await this.$router.replace({ query: this.options })
      }
      window.scrollTo(0, 0);
      localStorage.setItem(`itemsPerPage_${this.$route.name}`, this.options.itemsPerPage);
      this.loading = true;
      const filters = getFilters(this.headers);
      const query = ObjectToQuery({
        ...this.options,
        filter: 'deleted',
        filters,
      })
      const response = await axios.get(`/peoples/dt?${query}`);
      this.peoples = response.data.data.data
      this.peoplesTotal = response.data.data.total
      this.loading = false
    },

    dialogModal(dialog, type = this.dialogData.type, id = null) {
      this.dialogData.dialog = dialog;
      this.dialogData.type = type;
      this.dialogData.id = id;
    },
    $(key) {
      return this.$t(`${this.translationKey}.${key}`);
    },
  },

}
</script>
