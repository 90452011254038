var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.peoples,"options":_vm.options,"server-items-length":_vm.peoplesTotal,"loading":_vm.loading,"disable-filtering":true,"footer-props":{
      showFirstLastPage: true,
      itemsPerPageOptions:[10, 20, 50],
      itemsPerPageText: _vm.$t('dataTable.itemsPerPageText'),
      pageText: _vm.$t('dataTable.pageText')
    },"hide-default-header":!_vm.$vuetify.breakpoint.mobile},on:{"update:options":function($event){_vm.options=$event},"update:page":_vm.fetch,"update:items-per-page":_vm.fetch},scopedSlots:_vm._u([(!_vm.$vuetify.breakpoint.mobile)?{key:"header",fn:function(ref){
    var props = ref.props;
return [_c('datatable-header',{attrs:{"props":props,"options":_vm.options,"translation-key":_vm.translationKey},on:{"update:options":function($event){_vm.options=$event},"update:filters":_vm.fetch}})]}}:null,{key:"item.enter_all_cars",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.enter_all_cars ? 'success' : 'error',"label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(item.enter_all_cars ? 'مسموح' : 'غير مسموح')+" ")])]}},{key:"item.active",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{directives:[{name:"can",rawName:"v-can.disable",value:('update_peoples'),expression:"'update_peoples'",modifiers:{"disable":true}}],attrs:{"depressed":"","color":item.active ? 'success' : 'error',"rounded":"","small":"","outlined":""}},[_vm._v(" "+_vm._s(item.active ? 'مفعل' : 'معطل')+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" "+_vm._s(item.active ? 'mdi-check' : 'mdi-block-helper')+" ")])],1)]}},{key:"item.identifier_name",fn:function(ref){
    var item = ref.item;
return [(item.identifier)?_c('v-chip',{attrs:{"label":"","color":"black","small":"","dark":""}},[_vm._v(" "+_vm._s(item.identifier.name)+" ")]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("format_date")(item.created_at))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }